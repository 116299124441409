.x-tree-component{
    width: auto;
    min-width: 380px;
    text-align: left;
    display: inline-block;
}
.x-tree-component .chb-wp,
.x-tree-component .chb-wp .chb-dummy,
.x-tree-component .chb-wp .chb-input{
    border-radius: 1px;
}
.x-tree-component .chb-wp {
    width: 14px;
    height: 14px;
    border: 0;
    margin: 4px 3px 0 0;
    position: relative;
    vertical-align: top;
    display: inline-block;
}

.x-tree-component .chb-wp .chb-dummy,
.x-tree-component .chb-wp .chb-input{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}
.x-tree-component .chb-wp.hide{
    display: none;
}
.x-tree-component .chb-wp .chb-input{
    opacity: 0;
    z-index: 2;
    border: 1px solid transparent;
}
.x-tree-component .chb-wp .chb-dummy{
    border: 1px solid #ccc;
    z-index: 1;
    transition: background .1s ease-in;
    -moz-transition: background .1s ease-in; /* Firefox 4 */
    -webkit-transition: background .1s ease-in; /* Safari 和 Chrome */
    -o-transition: background .1s ease-in; /* Opera */
}
.x-tree-component .chb-wp .chb-input:disabled + .chb-dummy{
    background: #fff;
}
.x-tree-component .chb-wp .chb-input:checked + .chb-dummy{
    background: #3dce3d;
    border-color: #3dce3d;
}
.chb-wp .chb-input:checked+.chb-dummy:before{
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 12px;
    height: 5px;
    display: inline-block;
    border-left: 3px solid #3dce3d;
    border-bottom: 3px solid #3dce3d;
    border-radius: 5px;
    transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    -moz-transform: rotate(-50deg);
    -webkit-transform: rotate(-50deg);
    -o-transform: rotate(-50deg);
}
.x-tree-component .chb-wp .chb-input:checked + .chb-dummy:before{
    width: 14px;
    height: 6px;
    z-index: 9;
    border-radius: 4px;
    display: inline-block;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    -moz-transform: rotate(-50deg);
    -webkit-transform: rotate(-50deg);
    -o-transform: rotate(-50deg);
    transition: .3s ease-in;
}
.chb-dummy:after{
    content: '';
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 8;
    transition: .3s ease-in;
    -moz-transition: .3s ease-in; /* Firefox 4 */
    -webkit-transition: .3s ease-in; /* Safari 和 Chrome */
    -o-transition: .3s ease-in; /* Opera */

}
.x-tree-component .had-selected .chb-dummy:after{
    width: 4px;
    height: 4px;
    opacity: 1;
    zoom: 2;
    background: #3dce3d;
    border-radius: 1px;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%); 	    /* IE 9 */
    -moz-transform: translate(-50%,-50%); 	/* Firefox */
    -webkit-transform: translate(-50%,-50%);  /* Safari 和 Chrome */
    -o-transform: translate(-50%,-50%); 	    /* Opera */
}
.switch{
    width: 16px;
    height: 16px;
    display: inline-block;
}
.chb-switch{
    margin-top: 4px;
    border-radius: 2px;
    text-align: center;
    position: relative;
    vertical-align: top;
    /*border: 1px dotted #ccc;*/
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAQAQMAAACr5yReAAAABlBMVEUAAACqqqoTY3/sAAAAAXRSTlMAQObYZgAAADNJREFUeF51zKENACAMRNEjuQREh2aTrtORQB2iAkQxT/18SF0LsxHjapW+qUh/zfvJ8wEd1BW7qSFzoQAAAABJRU5ErkJggg==') 0 0  no-repeat;

}
/*.chb-switch:after{*/
    /*content: '';*/
    /*width: 70%;*/
    /*height: 1px;*/
    /*background: #999;*/
    /*position: absolute;*/
    /*top: 5px;*/
    /*left: 15%;*/
/*}*/
/*.chb-switch:before{*/
    /*content: '';*/
    /*width: 1px;*/
    /*height: 70%;*/
    /*background: #999;*/
    /*position: absolute;*/
    /*top: 15%;*/
    /*left: 5px;*/
/*}*/
.close > .chb-switch{
    /*display: none;*/
    background-position: -21px 0;

}
.x-tree-component .chb-list .chb-list{
    padding-left: 36px;
}
.x-tree-component .select-item{
    margin: 10px 0;
}
.x-tree-component .xtree-lab{
    width: auto;
    color: #666;
    font-size: 14px;
    cursor: pointer;
    max-width: 320px;
    vertical-align: top;
    display: inline-block;
    /*margin: 10px  0 10px 2px;*/
    border: 1px solid transparent;
}
.x-tree-component .xtree-lab:hover{
    border-bottom: 1px solid #aaa;
}
/*.select-item > ul{*/
    /*!*display: none;*!*/
/*}*/
/*.open > ul{*/
    /*display: block;*/
/*}*/
/*.x-tree-component .chb-list .chb-list{*/
    /*!*max-height: 0;*!*/
    /*!*overflow: hidden;*!*/
    /*list-style: none;*/
/*}*/
.x-tree-component .show,
.x-tree-component .hide,
.x-tree-component .open .chb-list,
.x-tree-component .close .chb-list{
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
}
.x-tree-component .show,
.x-tree-component .open .chb-list{
    display: block;
    max-height: 999px;
    /*-moz-transform: scaleY(1);*/
    /*-ms-transform: scaleY(1);*/
    /*-o-transform: scaleY(1);*/
    /*-webkit-transform: scaleY(1);*/
    /*transform: scaleY(1);*/
    animation: showAnimation 0.4s ease-in-out;
    -moz-animation: showAnimation 0.4s ease-in-out;
    -webkit-animation: showAnimation 0.4s ease-in-out;
    -moz-transition: max-height ,.5s ease-in-out;
    -o-transition: max-height .5s ease-in-out;
    -webkit-transition: max-height .5s ease-in-out;
    transition: max-height .5s ease-in-out;
}
.x-tree-component .hide,
.x-tree-component .close .chb-list{
    max-height: 0;
    transform: scaleY(0);
    -o-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -webkit-transform: scaleY(0);

    animation: hideAnimation 0.4s ease-out;
    -moz-animation: hideAnimation 0.4s ease-out;
    -webkit-animation: hideAnimation 0.4s ease-out;
    -moz-transition: max-height 0.5s ease-out;
    -o-transition: max-height 0.5s ease-out;
    -webkit-transition: max-height 0.5s ease-out;
    transition: max-height 0.5s ease-out;
}

@keyframes showAnimation {
    0% {
        -moz-transform: scale(1,0.1);
        -ms-transform: scale(1,0.1);
        -o-transform: scale(1,0.1);
        -webkit-transform: scale(1,0.1);
        transform: scale(1,0.1);
    }

    40% {
        -moz-transform: scale(1,1.03);
        -ms-transform: scale(1,1.03);
        -o-transform: scale(1,1.03);
        -webkit-transform: scale(1,1.03);
        transform: scale(1,1.03);
    }

    60% {
        -moz-transform: scale(1,0.99);
        -ms-transform: scale(1,0.99);
        -o-transform: scale(1,0.99);
        -webkit-transform: scale(1,0.99);
        transform: scale(1,0.99);
    }


    80% {
        -moz-transform: scale(1,1.01);
        -ms-transform: scale(1,1.01);
        -o-transform: scale(1,1.01);
        -webkit-transform: scale(1,1.01);
        transform: scale(1,1.02);
    }

    100% {
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        -webkit-transform: scale(1,1);
        transform: scale(1,1);
    }
}
@-moz-keyframes showAnimation {
    0% {
        -moz-transform: scale(1,0.1);
        -ms-transform: scale(1,0.1);
        -o-transform: scale(1,0.1);
        -webkit-transform: scale(1,0.1);
        transform: scale(1,0.1);
    }

    40% {
        -moz-transform: scale(1,1.03);
        -ms-transform: scale(1,1.03);
        -o-transform: scale(1,1.03);
        -webkit-transform: scale(1,1.03);
        transform: scale(1,1.03);
    }

    60% {
        -moz-transform: scale(1,0.99);
        -ms-transform: scale(1,0.99);
        -o-transform: scale(1,0.99);
        -webkit-transform: scale(1,0.99);
        transform: scale(1,0.99);
    }


    80% {
        -moz-transform: scale(1,1.01);
        -ms-transform: scale(1,1.01);
        -o-transform: scale(1,1.01);
        -webkit-transform: scale(1,1.01);
        transform: scale(1,1.02);
    }

    100% {
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        -webkit-transform: scale(1,1);
        transform: scale(1,1);
    }
}
@-webkit-keyframes showAnimation {
    0% {
        -moz-transform: scale(1,0.1);
        -ms-transform: scale(1,0.1);
        -o-transform: scale(1,0.1);
        -webkit-transform: scale(1,0.1);
        transform: scale(1,0.1);
    }

    40% {
        -moz-transform: scale(1,1.03);
        -ms-transform: scale(1,1.03);
        -o-transform: scale(1,1.03);
        -webkit-transform: scale(1,1.03);
        transform: scale(1,1.03);
    }

    60% {
        -moz-transform: scale(1,0.99);
        -ms-transform: scale(1,0.99);
        -o-transform: scale(1,0.99);
        -webkit-transform: scale(1,0.99);
        transform: scale(1,0.99);
    }


    80% {
        -moz-transform: scale(1,1.01);
        -ms-transform: scale(1,1.01);
        -o-transform: scale(1,1.01);
        -webkit-transform: scale(1,1.01);
        transform: scale(1,1.02);
    }

    100% {
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        -webkit-transform: scale(1,1);
        transform: scale(1,1);
    }
}
@keyframes hideAnimation {
    0% {
        -moz-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }

    60% {
        -moz-transform: scaleY(0.98);
        -ms-transform: scaleY(0.98);
        -o-transform: scaleY(0.98);
        -webkit-transform: scaleY(0.98);
        transform: scaleY(0.98);
    }

    80% {
        -moz-transform: scaleY(1.02);
        -ms-transform: scaleY(1.02);
        -o-transform: scaleY(1.02);
        -webkit-transform: scaleY(1.02);
        transform: scaleY(1.02);
    }

    100% {
        -moz-transform: scaleY(0);
        -ms-transform: scaleY(0);
        -o-transform: scaleY(0);
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}
@-moz-keyframes hideAnimation {
    0% {
        -moz-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }

    60% {
        -moz-transform: scaleY(0.98);
        -ms-transform: scaleY(0.98);
        -o-transform: scaleY(0.98);
        -webkit-transform: scaleY(0.98);
        transform: scaleY(0.98);
    }

    80% {
        -moz-transform: scaleY(1.02);
        -ms-transform: scaleY(1.02);
        -o-transform: scaleY(1.02);
        -webkit-transform: scaleY(1.02);
        transform: scaleY(1.02);
    }

    100% {
        -moz-transform: scaleY(0);
        -ms-transform: scaleY(0);
        -o-transform: scaleY(0);
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}
@-webkit-keyframes hideAnimation {
    0% {
        -moz-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }

    60% {
        -moz-transform: scaleY(0.98);
        -ms-transform: scaleY(0.98);
        -o-transform: scaleY(0.98);
        -webkit-transform: scaleY(0.98);
        transform: scaleY(0.98);
    }

    80% {
        -moz-transform: scaleY(1.02);
        -ms-transform: scaleY(1.02);
        -o-transform: scaleY(1.02);
        -webkit-transform: scaleY(1.02);
        transform: scaleY(1.02);
    }

    100% {
        -moz-transform: scaleY(0);
        -ms-transform: scaleY(0);
        -o-transform: scaleY(0);
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}