/*!
 * zeroModal.js
 * http://git.oschina.net/cylansad/zeroModal
 *
 * Copyright 2016, Sad
 */

.zeromodal-overlay {
    width: 100%;
    height: 1000px;
    position: absolute;
    z-index: 1000;
    opacity: 0.2;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
}

.zeromodal-loading1 {
    background: url(./img/loading1.gif);
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 35%;
}

.zeromodal-loading2 {
    background: url(./img/loading2.gif);
    width: 37px;
    height: 37px;
    position: absolute;
    left: 50%;
    top: 35%;
}

.zeromodal-container {

    font-family: "Hiragino Sans GB", "Microsoft YaHei", Arial-normal, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: absolute;
    box-shadow: 0 6px 24px rgba(0, 0, 0, .24);
    border-radius: 2px;
    border: none;
    background-color: #fff;
    z-index: 1001;
    padding: 19px 16px;
    box-sizing: border-box;
}

.zeromodal-header {
    margin-top: 4px;
    margin-bottom: 18px;
    color: #666;
    font-weight: bold;
    font-size: 14px;
}

.zeromodal-progress-content {
    position: absolute;
    left: 46%;
    font-size: 13px;
    color: #4FAB1C;
}

.zeromodal-body {
    width: 100%;
    min-height: 120px;
    font-size: 12px;
    color: #333;
    overflow-y: auto;
}

.zeromodal-frame {
    width: 100%;
    height: 100%;
    border: 0;
}

.zeromodal-btn {
    margin-bottom: 0;
    text-align: center;
    font-size: 14px;
    outline: none;
    display: inline-block;
    margin-left: 4px;
    font-weight: 400;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 8px;
    line-height: 20px;
    border-radius: 1px;
    min-width: 80px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .32);
    -webkit-transition: all;
    transition: all;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.zeromodal-btn:hover {
    box-shadow: 0 3px 4px rgba(0, 0, 0, .32);
}

.zeromodal-btn-primary {
    color: #fff;
    background-color: #04c356;
    border: none;
}

.zeromodal-btn-default {
    color: #333;
    background-color: #fff;
    border: none;
    padding: 6px 8px;
}

.zeromodal-max {
    background: url(./img/max.png);
    width: 16px;
    height: 16px;
    position: relative;
    float: right;
    margin-right: 3px;
    /*top: -4px;*/
    cursor: pointer;
    opacity: .3;
    /*-webkit-transition: -webkit-transform 0.4s ease-out;
    -moz-transition: -moz-transform 0.4s ease-out;
    -o-transition: -o-transform 0.4s ease-out;
    -ms-transition: -ms-transform 0.4s ease-out;
    -moz-user-select: none;
    -webkit-user-select: none;*/
}

.zeromodal-min {
    background: url(./img/min.png);
    width: 16px;
    height: 16px;
    position: relative;
    float: right;
    margin-right: 3px;
    /*top: -4px;*/
    cursor: pointer;
    opacity: .3;
    /*-webkit-transition: -webkit-transform 0.4s ease-out;
    -moz-transition: -moz-transform 0.4s ease-out;
    -o-transition: -o-transform 0.4s ease-out;
    -ms-transition: -ms-transform 0.4s ease-out;
    -moz-user-select: none;
    -webkit-user-select: none;*/
}

.zeromodal-max:hover {
    opacity: .6;
    filter: alpha(opacity=60);
    /*-webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    transform: rotateZ(180deg);*/
}

.zeromodal-min:hover {
    opacity: .6;
    filter: alpha(opacity=60);
    /*-webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    transform: rotateZ(180deg);*/
}

.modal-title-min {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.zeromodal-close {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAtklEQVQ4T62TURHCMBBEXxWAA8ABEsAJEg4n54SiACQUBQwOkMAcA5003YMZSj/T7MvebtIw8Wsm6ukBZtYCC2Dr7vcMbGZr4ASsYl8JiB9n4JpBCvHR3XdxyGCE1wYJUeIRIBYUJBNLgIDsgQPQ2y7zSVsonMyAC7BR4X4DRNpzoMuClYBi5lvEAkTFsp0RoBI/bX9qR9UYtuPkwcwZpL5IUvxOXUHqq7zM0haQcNj97zH9+iofmDp0EdfGQksAAAAASUVORK5CYII=) center no-repeat;
    width: 16px;
    height: 16px;
    position: relative;
    float: right;
    margin-right: 3px;
    /*top: -4px;*/
    cursor: pointer;
    opacity: .3;
    /*position: relative;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    top: -6px;
    outline: none;
    float: right;
    font-size: 19px;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    -webkit-transition: -webkit-transform 0.4s ease-out;
    -moz-transition: -moz-transform 0.4s ease-out;
    -o-transition: -o-transform 0.4s ease-out;
    -ms-transition: -ms-transform 0.4s ease-out;
    -moz-user-select: none;
    -webkit-user-select: none;*/
}

.zeromodal-close:hover {
    filter: alpha(opacity=50);
    opacity: .5;
}


/*.zeromodal-close:hover {
    -webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}*/

.zeromodal-footer {
    position: absolute;
    bottom: 12px;
    width: 100%;
    left: 0px;
}

.zeromodal-line {
    border: none;
    border-top: 1px solid #efefef;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 97%
}

.zeromodal-btn-container {
    text-align: right;
    padding-right: 15px;
}

.zeromodal-icon {
    display: none;
    width: 80px;
    height: 80px;
    border: 4px solid #808080;
    border-radius: 50%;
    margin: 16px auto 16px;
    padding: 0;
    position: relative;
    box-sizing: content-box;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.zeromodal-icon.zeromodal-icon-info {
    font-family: "Open Sans", sans-serif;
    color: #3fc3ee;
    border-color: #3fc3ee;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
}

.zeromodal-icon.zeromodal-icon-question {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #c9dae1;
    border-color: #c9dae1;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
}

.zeromodal-error {
    border-color: #f27474;
}

.zeromodal-icon.zeromodal-error .x-mark {
    position: relative;
    display: block;
}

.zeromodal-icon.zeromodal-error .line.left {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 17px;
}

.zeromodal-icon.zeromodal-error .line {
    position: absolute;
    height: 5px;
    width: 47px;
    background-color: #f27474;
    display: block;
    top: 37px;
    border-radius: 2px;
}

.zeromodal-icon.zeromodal-error .line.right {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 16px;
}

.zeromodal-icon.zeromodal-success {
    border-color: #a5dc86;
}

.zeromodal-icon.zeromodal-success::before {
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
}

.zeromodal-icon.zeromodal-success::after {
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 60px;
    transform-origin: 0 60px;
}

.zeromodal-icon.zeromodal-success .placeholder {
    width: 80px;
    height: 80px;
    border: 4px solid rgba(165, 220, 134, 0.2);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
}

.zeromodal-icon.zeromodal-success .fix {
    width: 7px;
    height: 90px;
    background-color: #fff;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.zeromodal-icon.zeromodal-success .line {
    height: 5px;
    background-color: #a5dc86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}

.zeromodal-icon.zeromodal-success .line.tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.zeromodal-icon.zeromodal-success .line.long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@-webkit-keyframes showZeroAlert {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    45% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
    80% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes showZeroAlert {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    45% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
    80% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes hideZeroAlert {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
}

@keyframes hideZeroAlert {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
}

.show-zero2 {
    -webkit-animation: showZeroAlert 0.3s;
    animation: showZeroAlert 0.3s;
}

.show-zero2.no-animation {
    -webkit-animation: none;
    animation: none;
}

.hide-zero2 {
    -webkit-animation: hideZeroAlert 0.15s;
    animation: hideZeroAlert 0.15s;
}

.hide-zero2.no-animation {
    -webkit-animation: none;
    animation: none;
}

.zeromodal-title1 {
    width: 100%;
    /*text-align: center;*/
    /*font-size: 20px;*/
    /*font-weight: bold;*/
    text-align: left;
    color: #575757;
    font-size: 17px;
    padding: 8px 0;
}

.zeromodal-title2 {
    width: 100%;
    text-align: center;
    color: #B7B7B7;
    font-size: 13px;
    padding: 8px 0;
}

.zeromodal-overflow-y {
    overflow-y: auto;
}

.zeromodal-progress {
    position: absolute;
    opacity: 0.8;
    width: 400px;
    height: 5px;
    overflow: visible;
    background: #f0f0f0;
    margin-bottom: 40px;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.zeromodal-progress .zeromodal-progress-bar {
    position: relative;
    animation: animate-positive 2s;
}

.zeromodal-progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.zeromodal-progress .zeromodal-progress-icon {
    width: 30px;
    height: 30px;
    line-height: 25px;
    border-radius: 50%;
    font-size: 13px;
    position: absolute;
    top: -14px;
    right: 0;
    background: #fff;
    border-width: 3px;
    border-style: solid;
    text-align: center;
}

.zeromodal-fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.zeromodal-progress-val {
    position: absolute;
    top: 10%;
    left: 10%;
}

.zeromodal-progress-val .line.tip {
    width: 13px;
    top: 13px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.zeromodal-progress-val .line {
    height: 5px;
    background-color: rgb(146, 194, 106);
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}

.zeromodal-progress-val .line.long {
    width: 22px;
    right: -26px;
    top: 10px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@-webkit-keyframes rotate_pacman_half_up {
    0% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

@keyframes rotate_pacman_half_up {
    0% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

@-webkit-keyframes rotate_pacman_half_down {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes rotate_pacman_half_down {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@-webkit-keyframes pacman-balls {
    75% {
        opacity: 0.7;
    }
    100% {
        -webkit-transform: translate(-100px, -6.25px);
        transform: translate(-100px, -6.25px);
    }
}

@keyframes pacman-balls {
    75% {
        opacity: 0.7;
    }
    100% {
        -webkit-transform: translate(-100px, -6.25px);
        transform: translate(-100px, -6.25px);
    }
}

.pacman {
    position: absolute;
}

.pacman > div:nth-child(2) {
    -webkit-animation: pacman-balls 1s 0s infinite linear;
    animation: pacman-balls 1s 0s infinite linear;
}

.pacman > div:nth-child(3) {
    -webkit-animation: pacman-balls 1s 0.33s infinite linear;
    animation: pacman-balls 1s 0.33s infinite linear;
}

.pacman > div:nth-child(4) {
    -webkit-animation: pacman-balls 1s 0.66s infinite linear;
    animation: pacman-balls 1s 0.66s infinite linear;
}

.pacman > div:nth-child(5) {
    -webkit-animation: pacman-balls 1s 0.99s infinite linear;
    animation: pacman-balls 1s 0.99s infinite linear;
}

.pacman > div:first-of-type {
    width: 0px;
    height: 0px;
    border-right: 25px solid transparent;
    border-top: 25px solid #a5dc86;
    border-left: 25px solid #a5dc86;
    border-bottom: 25px solid #a5dc86;
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_up 0.5s 0s infinite;
    animation: rotate_pacman_half_up 0.5s 0s infinite;
}

.pacman > div:nth-child(2) {
    width: 0px;
    height: 0px;
    border-right: 25px solid transparent;
    border-top: 25px solid #a5dc86;
    border-left: 25px solid #a5dc86;
    border-bottom: 25px solid #a5dc86;
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_down 0.5s 0s infinite;
    animation: rotate_pacman_half_down 0.5s 0s infinite;
    margin-top: -50px;
}

.pacman > div:nth-child(3),
.pacman > div:nth-child(4),
.pacman > div:nth-child(5),
.pacman > div:nth-child(6) {
    background-color: #a5dc86;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    width: 10px;
    height: 10px;
    position: absolute;
    -webkit-transform: translate(0, -6.25px);
    -ms-transform: translate(0, -6.25px);
    transform: translate(0, -6.25px);
    top: 25px;
    left: 100px;
}

@-webkit-keyframes line-scale-pulse-out {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
    50% {
        -webkit-transform: scaley(0.4);
        transform: scaley(0.4);
    }
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}

@keyframes line-scale-pulse-out {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
    50% {
        -webkit-transform: scaley(0.4);
        transform: scaley(0.4);
    }
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}

.line-scale-pulse-out {
    position: absolute;
}

.line-scale-pulse-out > div {
    background-color: #a5dc86;
    width: 4px;
    height: 35px;
    border-radius: 2px;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    -webkit-animation: line-scale-pulse-out 0.9s 0s infinite cubic-bezier(.85, .25, .37, .85);
    animation: line-scale-pulse-out 0.9s 0s infinite cubic-bezier(.85, .25, .37, .85);
}

.line-scale-pulse-out > div:nth-child(2),
.line-scale-pulse-out > div:nth-child(4) {
    -webkit-animation-delay: 0.2s !important;
    animation-delay: 0.2s !important;
}

.line-scale-pulse-out > div:nth-child(1),
.line-scale-pulse-out > div:nth-child(5) {
    -webkit-animation-delay: 0.4s !important;
    animation-delay: 0.4s !important;
}

@-webkit-keyframes line-scale-pulse-out-rapid {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
    80% {
        -webkit-transform: scaley(0.3);
        transform: scaley(0.3);
    }
    90% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}

@keyframes line-scale-pulse-out-rapid {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
    80% {
        -webkit-transform: scaley(0.3);
        transform: scaley(0.3);
    }
    90% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}

.line-scale-pulse-out-rapid > div {
    background-color: #a5dc86;
    width: 4px;
    height: 35px;
    border-radius: 2px;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    -webkit-animation: line-scale-pulse-out-rapid 0.9s 0s infinite cubic-bezier(.11, .49, .38, .78);
    animation: line-scale-pulse-out-rapid 0.9s 0s infinite cubic-bezier(.11, .49, .38, .78);
}

.line-scale-pulse-out-rapid > div:nth-child(2),
.line-scale-pulse-out-rapid > div:nth-child(4) {
    -webkit-animation-delay: 0.25s !important;
    animation-delay: 0.25s !important;
}

.line-scale-pulse-out-rapid > div:nth-child(1),
.line-scale-pulse-out-rapid > div:nth-child(5) {
    -webkit-animation-delay: 0.5s !important;
    animation-delay: 0.5s !important;
}

@-webkit-keyframes line-spin-fade-loader {
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@keyframes line-spin-fade-loader {
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

.line-spin-fade-loader {
    position: absolute;
}

.line-spin-fade-loader > div:nth-child(1) {
    top: 20px;
    left: 0;
    -webkit-animation: line-spin-fade-loader 1.2s 0.12s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.12s infinite ease-in-out;
}

.line-spin-fade-loader > div:nth-child(2) {
    top: 13.63636px;
    left: 13.63636px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0.24s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.24s infinite ease-in-out;
}

.line-spin-fade-loader > div:nth-child(3) {
    top: 0;
    left: 20px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0.36s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.36s infinite ease-in-out;
}

.line-spin-fade-loader > div:nth-child(4) {
    top: -13.63636px;
    left: 13.63636px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0.48s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.48s infinite ease-in-out;
}

.line-spin-fade-loader > div:nth-child(5) {
    top: -20px;
    left: 0;
    -webkit-animation: line-spin-fade-loader 1.2s 0.6s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.6s infinite ease-in-out;
}

.line-spin-fade-loader > div:nth-child(6) {
    top: -13.63636px;
    left: -13.63636px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0.72s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.72s infinite ease-in-out;
}

.line-spin-fade-loader > div:nth-child(7) {
    top: 0;
    left: -20px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0.84s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.84s infinite ease-in-out;
}

.line-spin-fade-loader > div:nth-child(8) {
    top: 13.63636px;
    left: -13.63636px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0.96s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0.96s infinite ease-in-out;
}

.line-spin-fade-loader > div {
    background-color: #a5dc86;
    width: 4px;
    height: 35px;
    border-radius: 2px;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    width: 5px;
    height: 15px;
}

@-webkit-keyframes square-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    }
    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg);
    }
    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0);
    }
}

@keyframes square-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    }
    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg);
    }
    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0);
    }
}

.square-spin {
    position: absolute;
}

.square-spin > div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    width: 50px;
    height: 50px;
    background: #a5dc86;
    border: 1px solid #a5dc86;
    -webkit-animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite;
}

.zeromodal-sweep-tee {
    border-width: 8px;
    border-color: #CECECE;
    border-left-width: 0;
    border-style: double;
    border-top-color: transparent;
    border-bottom-color: transparent;
    transform: rotateZ(220deg);
    position: absolute;
    right: 2px;
    bottom: 0;
    cursor: se-resize;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.zeromodal-top {
    cursor: move;
    height: 24px;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
}

.zeromodal-overflow-hidden {
    overflow: hidden;
}

.zeromodal-fixed {
    position: fixed !important;
}

/* prompt 弹窗 */
.zeromodal-prompt{
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    opacity: 0;
    font-size: 14px;
    text-align: center;
}
.prompt-success .zm-p-cnt{
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.prompt-warring .zm-p-cnt{
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.prompt-danger .zm-p-cnt{
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
.zm-p-cnt{
    position: relative;
    display: inline-block;
    width: 960px;
    text-align: left;
    background: #fff;

    padding: 15px 65px 15px 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    box-sizing: border-box;
}
.zm-p-cnt .zeromodal-close{
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    float: right;
}

/*整体部分*/
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    margin: 2px;
}
/*滑动轨道*/
::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #fefefe;
}
::-webkit-scrollbar-track:hover{
    background: #F8F8F8;
    -webkit-box-shadow: inset 0 0 5px rgba(159, 159, 159, 0.1);
}
/*滑块*/
::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(173, 171, 171, 0.2);
}
/*滑块效果*/
::-webkit-scrollbar-thumb:hover {
    border-radius: 6px;
    background: rgba(29, 29, 29, 0.4);
}