.exam-paper input, .exam-paper textarea,
.exam-paper select, .exam-paper button{
    outline: none;
}
.exam-paper button{
    cursor: pointer;
}
.exam-paper{
    font-size: 14px;
    line-height: 160%;
    word-break: break-all;
    word-wrap: break-word;
    position: relative;
}
/* 多选框 */
.exam-paper .chb-wp.checkbox,
.exam-paper .checkbox .chb-input,
.exam-paper .checkbox .chb-dummy{
    border-radius: 1px;
}
.exam-paper .chb-wp.radio,
.exam-paper .radio .chb-input,
.exam-paper .radio .chb-dummy{
    border-radius: 8px;
}
.exam-paper .chb-wp {
    width: 13px;
    height: 13px;
    border: 0;
    position: relative;
    margin: -5px 5px 0 0;
    display: inline-block;
}
.exam-paper .chb-wp.radio {
    width: 16px;
    height: 16px;
    vertical-align: -1px;
}

.exam-paper .chb-wp .chb-dummy,
.exam-paper .chb-wp .chb-input{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}
.exam-paper .chb-wp .chb-input{
    opacity: 0;
    z-index: 2;
    border: 1px solid transparent;
}
.exam-paper .chb-wp .chb-dummy{
    border: 1px solid #ccc;
    z-index: 1;
}
.exam-paper .checkbox .chb-input:disabled + .chb-dummy{
    background: #eee;
}
.exam-paper .checkbox .chb-input:checked + .chb-dummy{
    background: #04c356;
    border-color: #04c356;
}
.exam-paper .checkbox .chb-input:checked + .chb-dummy:before{
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 10px;
    height: 6px;
    display: inline-block;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-radius: 3px;
    transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    -moz-transform: rotate(-50deg);
    -webkit-transform: rotate(-50deg);
    -o-transform: rotate(-50deg);
}
.exam-paper .radio .chb-input:checked + .chb-dummy:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 55%;
    height: 55%;
    border: none;
    border-radius: 60%;
    background: #ea562e;
    display: inline-block;
    transform: translate(-50%, -50%)
}
.exam-paper-warp{
    text-align: center;
}
.exam-paper{
    width: 1180px;
    overflow: auto;
    text-align: left;
    display: inline-block;
}

.pp-lf{
    float: left;
    width: 866px;
    min-height: calc(100vh - 50px);
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
}
.exam-utils{
    position: absolute;
    top: 0;
    left: 0;
    width: 294px;
    margin-top: 0;
}
.fixed{
    position: fixed;
    top: 10px;
}
.pp-rh{
    float: right;
    width: 294px;
    position: relative;
}
.exam-info{
    padding: 0 14px 10px 14px;
}
.exam-state{
    width: 100%;
    line-height: 44px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    background: #04c356;
}
.exam-paper .exam-name{
    font-size: 16px;
    color: #323232;
    text-align: left;
    padding: 18px 0;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}
.exam-paper .exam-timer {
    line-height: 74px;
    padding: 8px 0;
    font-size: 40px;
    color: #04c356;
    text-align: center;
}
.exam-paper .exam-pp-info{
    font-size: 14px;
    text-align: left;
    color: #8e8e8e;
    padding: 10px 0;
    line-height: 24px;
}
.exam-paper .btn-submit-paper{
    width: 100%;
    height: 44px;
    border: none;
    color: #fff;
    background: #04c356;
    display: inline-block;
}
.answer-sheet-warp,
.bar-opr{
    /*width: 100%;*/
    background-color: #fff;
}
.answer-sheet-warp{
    padding: 14px;
}
.bar-opr{
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
}
.opr-btns{
    padding: 10px 0;
    background: #fff;
}
.opr-row{
    height: 50px;
    line-height: 50px;
}

.count-down{
    min-width: 62px;
    display: inline-block;

}

.icon-alarm-clock{
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -6px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcBAMAAACAI8KnAAAAHlBMVEUAAAC7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7tPv+vRAAAACXRSTlMAweQXNXBRgI8FULk4AAAAtUlEQVR4Xl2RMQuDQAyFHyoH3YTSIZugSzdbKMVN3BxLp27+Def+gcbaIf+2dyHHwb0p3/ESkncIqh7EpxEmdxavzbgkX/sX7hUn4SdwI+m0UbbFWoL9KkeoLtICIFawyunbWqtvRCFLRCcNZkZE0I7XL6Gv6ZtwYNAn4YEhTcJCInaGasYkbzXbqHINiw8chisTj6GeOWZQg3ZdUnXvw5KVtEp2QnZgdn4WTh5dHiyqFHv2KX9iUEEQkYNj6wAAAABJRU5ErkJggg==) center/cover no-repeat;
}

.asw-st-list{
    /*max-height: calc(100vh - 230px - 216px);*/
    overflow-y: auto;
    color: #8e8e8e;
}

.fixed .asw-st-list{
    max-height: calc(100vh - 230px);
}

.asw-st-tt{
    height: 36px;
    line-height: 36px;
    color: #04c356;
    font-size: 16px;
    border-bottom:1px solid #e5e5e5;
}

.asw-total{
    font-size: 14px;
    float: right;
    color: #323232;
}

.asw-mods{
    padding: 10px;
}
.asw-mods .btn-back{
    width: 100%;
    line-height: 42px;
    font-size: 16px;
    color: #323232;
    text-align: center;
    border: 1px solid #dcdcdc;
    background: #f4f4f4;
    display: block;
}
.asw-mod{
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-top: -2px;
    display: inline-block;
    border: 1px solid #e1e1e1;
    vertical-align: middle;
}

.asw-ul{
    margin: 20px 0;
    overflow: auto;

}
.asw-item{
    width: 34px;
    padding: 0;
    float: left;
    font-size: 14px;
    margin: 0 10px 10px 0;
}
.asw-item:nth-of-type(6n){
    margin-right: 0;
}

.btn-as{
    width: 34px;
    height: 34px;
    color: #8e8e8e;
    font-size: 16px;
    background: none;
    display: inline-block;
    border: 1px solid #eee;

}
.btn-as:hover{
    border: 1px solid #04c356;
}
.btn-as.right {
    background-color: #9b0;
    color: #fff;
}
.btn-as.error {
    background-color: #e53333;
    color: #fff;
}

/* 答案已选中 */
.checked {
    color: #04c356;
}


/* 已答题 */
.answered{
    color: #6d6969;
    background: #F4F4F4;
    border: 1px solid #E5E5E5;
}


/* 试题 */
.pp-mdl-list{
    background: #fffdfb;
    /*padding: 0 30px;*/
    margin-top: 10px;
}

.pp-name{
    color: #ea562e;
    font-size: 20px;
    line-height: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
}
.pp-top-btns{
    overflow: auto;
}
.pp-mdl-qsts{
    width: 720px;
    float: left;
    margin-bottom: 10px;
}
.single-warp{
    width: 100px;
    float: left;
    line-height: 40px;
    font-size: 18px;
    color: #323232;
}
.single-warp label {
    width: auto;
}

.btn-mdl{
    min-width: 102px;
    height: 40px;
    color: #666;
    border: 1px solid #bbb;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
    display: inline-block;
    transition: all .2s;
    margin: 0 10px 10px 0;
    background: none;

}
.btn-mdl:nth-of-type(6n){
    margin-right: 0;
}
.btn-mdl.active{
    color: #fff;
    background: #04c356;
}
.btn-mdl.active,
.btn-mdl:hover{
    border-color: #04c557;
}
.qst-list,
.qst-opts{
    padding: 0;
    list-style: none;
}
.mdl-qst-list{
    padding: 20px 0;
    display: none;
    border-top: 1px solid #F3E2D5;
}
.mdl-qst-list img{
    max-width: 600px;
    max-height: 200px;
}
.mdl-qst-list:first-child {
    border-top: 0;
}
.mdl-explain{
    color: #666;
}
.qst-cnt,
.qst-opts{
    padding: 0 30px;
}
/* 案例分析 */
.mdl-case-analysis{
    padding: 10px 5px;
}
.mdl-case-analysis .als-tt{
    font-size: 18px;
    color: #FF1456;
    padding: 0 10px;
    margin-bottom: 10px;
    border-left: 6px solid #FF8448;
}
.mdl-case-analysis .als-cnt{
    color: #989898;
    text-indent: 26px;
}
.qst-list .qst-item{
    display: none;
    margin-top: 30px;
    margin-bottom: 20px;
}
.qst-item .chb-wp{
    pointer-events: none;
}

.qst-list .qst-item.active, .mdl-qst-list.active{
    display: block;
}
.mdl-qst-list.active-one {
    border-top: none;
}
.qst-cnt{
    overflow: auto;
}

.qst-type-warp{
    float: left;
    margin-right: 5px;
}

.qst-type{
    color: #FF8646;
    margin-top: -1px;
}

.qst-type i{
    color: #8e8e8e;
}
.qst-order{
    height: 20px;
    min-width: 20px;
    float: left;
    color: #8e8e8e;
    font-size: 16px;
    line-height: 20px;
    margin-right: 10px;
    text-align: center;
    background: #f5f5f5;
    display: inline-block;
    border: 1px solid #e3e3e3;
}
.qst-name{
    float: left;
    font-size: 16px;
    max-width: 670px;
    line-height: 22px;
}
.qst-opts{
    margin: 20px 0 20px 30px;

}
.qst-opts li {
    color: #999;
    font: 14px/30px "Arial";
}

/* 问题选项 */
.opt-item{
    margin-bottom: 16px;
}
.opt-item > *{
    display: inline-block;
}
.ord-chb-wp{
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
}
.ord-chb-dummy,
.ord-chb-input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
}
.ord-chb-input{
    opacity: 0;
    z-index: 2;
}
.ord-chb-dummy{
    background: #f2f2f2;
    border-radius: 50%;
    z-index: 1;
    text-align: center;
}
.ord-chb-input:checked + .ord-chb-dummy{
    color: #fff;
    background: #04c356;
}


/* 选项答案 */
.qst-asw-list{
    /*margin-top: 20px;*/
    padding: 10px 30px;
}
.lab-ord-chb{
    width: 100%;
    cursor: pointer;
}
.lab-ord-chb:hover{
    text-decoration: dashed;
    color: #323232;
}
.lab-asw{
    margin-right: 30px;
    border-radius: 3px;
    padding: 3px 5px;
    cursor: pointer;
}
.lab-asw:hover{
    background: #eee;
}
.exam-btns{
    display: none;
    text-align: right;
    padding: 30px 0 60px 0;
}
.btn-prev,
.btn-next{
    width: 200px;
    height: 46px;
    color: #04c356;
    font-size: 16px;
    margin-right: 10px;
    background: #fff;
    border: 1px solid #e2e2e2;
    display: inline-block;
}
.btn-prev:disabled,
.btn-next:disabled{
    cursor: not-allowed;
    background: #f1f1f1;
    color: #8e8e8e;
    border: 1px solid #e2e2e2;
}
.btn-prev:not(:disabled):hover,
.btn-next:not(:disabled):hover{
    border: 1px solid #04c356;
    color: #04c356;
    background: #EBFFF4;
}

/* 文本输入框 */
.qst-asw-text{
    width: 160px;
    height: 22px;
    border: none;
    font-size: 16px;
    padding: 5px;
    vertical-align: bottom;
    border-bottom: 1px solid #ccc;
}
.qst-asw-text:focus{
    border-bottom: 1px solid #04c557;
}
.qst-asw-textarea{
    width: 710px;
    height: 120px;
    padding: 5px 10px;
    resize: none;
    border: 1px solid #bbb;
}
.qst-asw-textarea:focus{
    border: 1px solid #04c557;
}
.btn-close-win{
    color: #666;
}
.btn-close-win:hover{
    text-decoration: none;
}

/* 历史记录 */
.qst-opt-warp{
    padding: 10px;
    overflow: auto;
    font-size: 15px;
}

.btn-show-asw {
    border: 0;
    float: right;
    background: none;
    outline: none;
    margin: 0 15px;
    padding-left: 20px;
    position: relative;
}
.btn-show-asw .icon-down-arrow{
    position: absolute;
    top: 1px;
    left: -2px;
    width: 22px;
    height: 22px;
    display: inline-block;
    /*border-left: 1px solid #8e8e8e;*/
    /*border-bottom: 1px solid #8e8e8e;*/
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg2MzkwQ0RFODVEMTFFNzkzNkJGQzkzNDA0NzMwNjQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg2MzkwQ0NFODVEMTFFNzkzNkJGQzkzNDA0NzMwNjQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NEMyQzg3NzFFODRDMTFFN0FERTJGMTBGQTA5MzI0QUQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NEMyQzg3NzJFODRDMTFFN0FERTJGMTBGQTA5MzI0QUQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6LO7AHAAAAgElEQVR42mL8//8/AymAiYFEQLIGlvT09MdAOh6I9xFQ6wHE00A2JADxMiC2xqPYHogXg9SCNOwF4jggXotDkx0QrwHiECA+BPPDLiRNVmiKQWKhQHwQ7AckyV1Qv6yDmsYKxCuBOAyID8A9jWb9TiCOhSpkQFcMAoyDL+IAAgwAazwbZMnkPtsAAAAASUVORK5CYII=') center no-repeat;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg2MzkwQ0RFODVEMTFFNzkzNkJGQzkzNDA0NzMwNjQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg2MzkwQ0NFODVEMTFFNzkzNkJGQzkzNDA0NzMwNjQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NEMyQzg3NzFFODRDMTFFN0FERTJGMTBGQTA5MzI0QUQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NEMyQzg3NzJFODRDMTFFN0FERTJGMTBGQTA5MzI0QUQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6LO7AHAAAAgElEQVR42mL8//8/AymAiYFEQLIGlvT09MdAOh6I9xFQ6wHE00A2JADxMiC2xqPYHogXg9SCNOwF4jggXotDkx0QrwHiECA+BPPDLiRNVmiKQWKhQHwQ7AckyV1Qv6yDmsYKxCuBOAyID8A9jWb9TiCOhSpkQFcMAoyDL+IAAgwAazwbZMnkPtsAAAAASUVORK5CYII=',sizingMethod=scale);

    /*-webkit-transform: rotate(-45deg);*/
    /*-moz-transform: rotate(-45deg);*/
    /*-ms-transform: rotate(-45deg);*/
    /*-o-transform: rotate(-45deg);*/
    /*transform: rotate(-45deg);*/
    -webkit-transform-origin: 10px 10px;
    -moz-transform-origin: 10px 10px;
    -ms-transform-origin: 10px 10px;
    -o-transform-origin: 10px 10px;
    transform-origin: 10px 10px;
    -webkit-transition: -webkit-transform .2s ease-in;
    transition: -webkit-transform .2s ease-in;
    -o-transition: -o-transform .2s ease-in;
    -moz-transition: transform .2s ease-in, -moz-transform .2s ease-in;
    transition: transform .2s ease-in;
    transition: transform .2s ease-in, -webkit-transform .2s ease-in, -moz-transform .2s ease-in, -o-transform .2s ease-in;
}
.hide.qst-bottom {
    height: 0;
    overflow: hidden;
    border-top: 1px solid #eee;
}
.hide-asw{
    display: none;
}
.hide .hide-asw {
    display: block;
}
.hide .show-asw {
    display: none;
}
.hide .icon-down-arrow {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
